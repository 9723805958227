import { Typography, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'utilities/store';
import { uiSettings } from '../etc/settings';
import Branding from './Branding';

interface StyleProps {
  backgroundColour?: string,
  foregroundColour?: string, 
}
const useStyles = makeStyles((theme) => ({
  appBar: (props: StyleProps) => ({
    height: uiSettings.header.appBarHeight,
    backgroundColor: props.backgroundColour || uiSettings.header.background,
  }),
  title: (props: StyleProps) => ({
    flexGrow: 1,
    marginLeft: 24,
    '& div': {
      position: 'relative',
      display: 'inline-block'
    },
    color: props.foregroundColour || 'white',
  }),
  version: {
    position: 'absolute',
    fontSize: 10,
    left: 0,
    bottom: -10
  },
  logo: {
    height: 30,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const ElevationScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const BorderLinearProgress = withStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 5,
    zIndex: 1200 // above the appbar
  },
  bar: {
  },
})(LinearProgress);

interface Props {
  title?: string,
  expanded?: boolean,
  mobileMenuToggle?: boolean,
}
interface InjectedProps {
  loading: number,
  companyName?: string,
  companyDescription?: string,
  backgroundColour?: string,
  foregroundColour?: string,
  logoUrl?: string,
}
const TopNavigation = (props: Props & InjectedProps & any) => {
  const bigScreen = useMediaQuery('(min-width:600px)');
  const classes = useStyles({
    backgroundColour: props.backgroundColour,
    foregroundColour: props.foregroundColour
  });
  const {
    logoUrl,
    companyName,
    companyDescription,
    loading = 0,
    title = '',
    expanded = true,
    //mobileMenuToggle = null
  } = props;

  return (
    <React.Fragment>
      <BorderLinearProgress hidden={loading === 0} />
      <ElevationScroll {...props}>
        <AppBar color='inherit' className={classes.appBar}>
          <Toolbar style={{ padding: '30px' }}>
            {companyName === undefined && logoUrl === undefined && (
              <Branding title={title} expanded={expanded} />
            )}
            {(companyName !== undefined || logoUrl !== undefined) && (
              <>
                {logoUrl && (<img src={logoUrl} className={classes.logo} alt="Company Logo" />)}
                {companyName && bigScreen && (
                  <div className={classes.title}>
                    <div>
                      <Typography variant="h6" noWrap>{companyName}</Typography>
                      <Typography variant="h6" noWrap className={classes.version}>{companyDescription}</Typography>
                    </div>
                    <div>
                      <Typography style={{ marginLeft: 8 }} variant="h6" noWrap>{title ? ' / ' : ''}{title}</Typography>
                    </div>
                  </div>
                )}
              </>
            )}

          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
};

const mapStateToProps = (app: AppState) => ({
  loading: app.state.loading,
  companyName: app.state.companyName,
  companyDescription: app.state.companyDescription,
  backgroundColour: app.state.backgroundColour,
  foregroundColour: app.state.foregroundColour,
  logoUrl: app.state.logoUrl,
});

export default connect(mapStateToProps)(TopNavigation);
