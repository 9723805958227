import { FormControl, FormControlLabel, Grid, Checkbox as MuiCheckbox, FormHelperText } from '@material-ui/core'
import React from 'react'
import { DynamicFieldProps } from './types'

const getValue = (v: boolean | string) => v === true || v === 'true'

const Checkbox = (props: DynamicFieldProps) => {
  const { field,mandatory } = props
  const [value, setValue] = React.useState(getValue(props.value))
  React.useEffect(() => {
    setValue(getValue(props.value))
  }, [props.value])
  return (
    <Grid item md={props.width} xs={12}>
      <FormControl error={field.Error} style={props.style} required={mandatory} >
        <FormControlLabel
          control={
            <MuiCheckbox
              inputRef={props.reference}
              aria-readonly={field.ReadOnly}
              disabled={field.ReadOnly}
              checked={value}
              onChange={(e) => {
                setValue(e.target.checked)
                props.formValueChanged && props.formValueChanged(field.Name, e.target.checked)
              }}
              name={field.Name}
            />
          }
          label={field.Label}
        />
        <FormHelperText>{field.ErrorText}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

export default Checkbox
