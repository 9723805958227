import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, Paper } from '@material-ui/core';
import Button from 'components/Buttons';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { DynamicFieldProps } from './types';


const Diagram: React.FC<DynamicFieldProps> = (props) => {
  const [diagramCanvas, setDiagramCanvas] = React.useState<SignatureCanvas | undefined>(undefined);
  const containerRef = React.createRef<HTMLElement>();

  const [value, setValue] = React.useState(props.value);
  const [open, setOpen] = React.useState<boolean>(false);
  const [diagramWidth, setDiagramWidth] = React.useState(0);
  const [diagramHeight, setDiagramHeight] = React.useState(0);
  const [diagramThumbWidth, setDiagramThumbWidth] = React.useState(0);
  const [diagramThumbHeight, setDiagramThumbHeight] = React.useState(0);
  const [shadowDepth, setShadowDepth] = React.useState(2);


  window.addEventListener('resize', () => {
    // this will 'dirty' the component and force a re-render which will recalculte the 
    // canvas boundaries - SignatureCanvas doesn't have typings so we'll use 'any'
    setDiagramCanvasSizeUsingComponentReference(containerRef.current);
  })

  const setDiagramCanvasReference = (ref: any) => {
    setDiagramCanvas(ref);
    setDiagramCanvasSizeUsingComponentReference(containerRef.current);
    setDiagramData(ref, value);
  };

  const setDiagramData = (ref: any, data: string) => {
    if (ref) {
      ref.fromDataURL(data);
    }
  };

  const setDiagramCanvasSizeUsingComponentReference = (ref: HTMLElement | null) => {
    if (ref) {
      setDiagramWidth(ref.offsetWidth);
      setDiagramHeight(ref.offsetHeight);
      setThumbnailDimensions(ref, 300);
    }
  };

  const setThumbnailDimensions = (ref: HTMLElement | null, max: number) => {
    if (ref) {
      const ratio = ref.offsetWidth / ref.offsetHeight;
      setDiagramThumbWidth((ratio >= 1) ? max : max * ratio);
      setDiagramThumbHeight((ratio < 1) ? max : max / ratio);
    }
  };

  const handleOk = async () => {
    const dataUrl = (diagramCanvas as any).toDataURL('image/png');
    await setValue(dataUrl);
    setOpen(false);
  };

  const handleOpen = () => {
    if (!props.field.ReadOnly) {
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Grid item md={props.width} xs={12}>

      <FormControl
        aria-readonly={props.field.ReadOnly}
        disabled={props.field.ReadOnly}
        error={props.field.Error}
        required={props.mandatory}
        style={Object.assign({}, { maxWidth: diagramThumbWidth, minWidth: diagramThumbWidth }, props.style)}>
        {!value && (
          <Button.CreateButton label="create diagram" click={() => handleOpen()} />
        )}
        {value && (
          <div onClick={(e) => handleOpen()}>
            <div style={{ marginTop: 16, width: diagramThumbWidth, height: diagramThumbHeight }}>
              <Paper
                onMouseMove={(event) => setShadowDepth(4)}
                onMouseOut={(event) => setShadowDepth(2)}
                elevation={shadowDepth}
                style={{ width: '100%', height: '100%' }}
              >
                <InputLabel shrink={!value}>{props.field.Label || 'Diagram'}</InputLabel>
                <div onClick={() => handleOpen()}>
                  {value && <img ref={props.reference} width={diagramThumbWidth} height={diagramThumbHeight} src={value || props.value} alt="signature" />}
                </div>
              </Paper>
            </div>
          </div>
        )}
      </FormControl>

      <Dialog fullScreen maxWidth="md" open={open === true} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {props.field.Label || 'Diagram'}
        </DialogTitle>
        <DialogContent >
          <Paper elevation={3} ref={containerRef} style={{ width: '100%', height: '100%' }}>
            <SignatureCanvas ref={(ref: any) => setDiagramCanvasReference(ref)}
              penColor='blue'
              canvasProps={{ width: diagramWidth, height: diagramHeight, className: 'sigCanvas' }}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button.OkButton click={async () => handleOk()} />
          <Button.CancelButton click={() => handleCancel()} />
        </DialogActions>
      </Dialog>

    </Grid >
  );
};

export default Diagram;
