import moment from 'moment';
import { DynamicFormDefaults } from './constants';
import { DynamicFormField, DynamicFormFieldGroup } from './types';

export const getStyle = (field: DynamicFormField, theme) => {
  return {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: field.MinWidth ?? '200px',
    width: '90%'
  };
};

export const parseDateInput = (v: any, additionalFormats: string[] = []) => {
  if (v && typeof v === 'string') {
    const formats = [
      moment.ISO_8601,
      moment.RFC_2822,
      DynamicFormDefaults.format.moment.dateTime,
      DynamicFormDefaults.format.moment.date,
      DynamicFormDefaults.format.moment.time,
      ...additionalFormats,
    ];
    const m = moment(v, formats);
    if (m.isValid()) return m;
  }
  return moment();
}

export const getVisible = (field: DynamicFormField | DynamicFormFieldGroup): boolean => {
  return field.Visible ?? true;
};

export const getMandatory = (field: DynamicFormField): boolean => {
  return field.Mandatory ?? false;
};

export const getReadOnly = (field: DynamicFormField): boolean => {
  return field.ReadOnly ?? false;
};
