import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/Group';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import PublishIcon from '@material-ui/icons/Publish';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SendIcon from '@material-ui/icons/Send';


const useStyles = makeStyles(theme => ({

  buttonIcon: {
    '& > svg': {
      marginRight: theme.spacing(1),
    }
  },
  buttonProgressSpinner: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
}));

export interface ButtonProps extends MuiButtonProps {
  disableOnClick?: boolean,
  click?: (e: any) => void,
  icon?: JSX.Element,
  label?: string | JSX.Element,
  fullscreen?: boolean
}

const Button = {
  Button: (props: ButtonProps) => {
    const classes = useStyles();
    const [clicked, setClicked] = React.useState(false);
    const { disabled, disableOnClick, click, icon, label, ...theRestOfTheProps } = props;
    return (
      <MuiButton {...theRestOfTheProps}
        size="large"
        disableElevation
        variant="outlined" 
        color="primary"
        disabled={disabled || (disableOnClick && clicked)}
        onClick={e => { setClicked(true); click && click(e) }}
        startIcon={icon}
      >
        <>
          {label}
        </>
        {disableOnClick && clicked && (
          <CircularProgress size={24} className={classes.buttonProgressSpinner} />
        )}
      </MuiButton>
    );
  },
  SubmitButton: (props: ButtonProps) => {
    const { label, icon } = props;
    const classes = useStyles();
    return (
      <Button.Button {...props}
        className={classes.submitButton}
        type="submit"
        icon={icon || <PublishIcon />}
        label={label || <FormattedMessage id="submit" defaultMessage="Submit" />}
      />
    );
  },
  UploadButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <CloudUploadIcon />}
        label={label || <FormattedMessage id="upload" defaultMessage="Upload" />}
      />
    );
  },
  DeleteButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <DeleteIcon />}
        label={label || <FormattedMessage id="delete" defaultMessage="Delete" />}
      />
    );
  },
  EditButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <EditIcon />}
        label={label || <FormattedMessage id="edit" defaultMessage="Edit" />}
      />
    );
  },
  DetailsButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <EditIcon />}
        label={label || <FormattedMessage id="details" defaultMessage="Details" />}
      />
    );
  },
  DownloadButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <CloudDownloadIcon />}
        label={label || <FormattedMessage id="download" defaultMessage="Download" />}
      />
    );
  },
  CreateButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <AddIcon />}
        label={label || <FormattedMessage id="new" defaultMessage="New" />}
      />
    );
  },
  MembersButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <GroupIcon />}
        label={label || <FormattedMessage id="members" defaultMessage="Members" />}
      />
    );
  },
  MoveButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <OpenWithIcon />}
        label={label || <FormattedMessage id="move" defaultMessage="Move" />}
      />
    );
  },
  YesButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <CheckIcon />}
        label={label || <FormattedMessage id="yes" defaultMessage="Yes" />}
      />
    );
  },
  OkButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        icon={icon || <CheckIcon />}
        label={label || <FormattedMessage id="ok" defaultMessage="OK" />}
      />
    );
  },
  CancelButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || <CancelIcon />}
        label={label || <FormattedMessage id="close" defaultMessage="Close" />}
      />
    );
  },
  NoButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || <CancelIcon />}
        label={label || <FormattedMessage id="no" defaultMessage="No" />}
      />
    );
  },
  NextButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || <SkipNextIcon />}
        label={label}
      />
    );
  },
  PreviousButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || <SkipPreviousIcon />}
        label={label}
      />
    );
  },
  FullscreenButton: (props: ButtonProps) => {
    const { label, icon, fullscreen } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        label={label}
      />
    );
  },
  NotificationButton: (props: ButtonProps) => {
    const { label, icon } = props;
    return (
      <Button.Button {...props}
        color='secondary'
        icon={icon || <SendIcon />}
        label={label}
      />
    );
  },
};

export default Button;  