import { CssBaseline, makeStyles } from '@material-ui/core';
import RouteNotFound from 'components/RouteNotFound';
import TopNavigation from 'components/TopNavigation';
import AnonymousFormPage from 'containers/pages/AnonymousFormPage';
import { uiSettings } from 'etc/settings';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientInfo } from 'utilities/helpers';
import store from 'utilities/store';
import { setClientInfo } from 'reducers/stateReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    paddingTop: uiSettings.header.appBarHeight,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    margin: theme.spacing(0)
  }
}));

function App() {
  const classes = useStyles();
  clientInfo()
    .then(info => {
      store.dispatch(setClientInfo({
        ip: info.ip,
        userAgent: info.uag,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC'
      }));
    });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.content} >
        <Switch>
          <Route exact path="/:id" render={() => (
            <>
              <TopNavigation />
              <AnonymousFormPage />
            </>
          )} />
          <Route exact path="/entry/:id" render={() => (
            <>
              <TopNavigation />
             <AnonymousFormPage />
            </>
          )} />
          <Route exact path="/:id/:formid" render={() => (
            <>
              <TopNavigation />
              <AnonymousFormPage />
            </>
          )} />
          <Route render={() => <RouteNotFound />} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
