import { Grid } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React from 'react';
import { DynamicFieldProps } from './types';
import { parseDateInput } from './helpers';
import { DynamicFormDefaults } from './constants';


const checkDateFormat = (format: string, defaultFormat: string) => {
  const incompatible: string[] = ['g', 'd', 't', 'G', 'D', 'T'];
  if (!format || incompatible.includes(format.trim())) {
    return defaultFormat;
  }
  return format;
};

const DatePicker = (props: DynamicFieldProps) => {
  const { field, mandatory } = props;
  const format = checkDateFormat(field.TypeDetail, DynamicFormDefaults.format.dateFns.dateTime);
  const [value, setValue] = React.useState<Date>(new Date());
  
  React.useEffect(() => {
    setValue(parseDateInput(props.value).toDate());
  }, [props.value]);

  if (field.Visible === false) {
    return null;
  }

  return (
    <Grid item md={props.width} xs={12}>
      <KeyboardDateTimePicker
        inputRef={props.reference}
        error={field.Error}
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        style={props.style}
        margin="normal"
        id={field.Name}
        label={field.Label}
        format={format}
        value={value}
        required={mandatory}
        onChange={date => {
          date && setValue(date);
          props.formValueChanged && props.formValueChanged(field.Name, date);
        }}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
      />
    </Grid>
  );
};

export default DatePicker;
