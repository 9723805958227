export const START_REQUEST = 'workaware/app/state/START_REQUEST';
export const END_REQUEST = 'workaware/app/state/END_REQUEST';
export const SET_PAGE_TITLE = 'workaware/app/state/SET_PAGE_TITLE';
export const SET_FORM_PROPERTIES = 'workaware/form/state/SET_FORM_PROPERTIES';
export const SET_CLIENT_INFO = 'workaware/form/state/SET_CLIENT_INFO';

interface IFormProperties {
  companyName?: string,
  companyDescription?: string,
  backgroundColour?: string,
  foregroundColour?: string,
  logoUrl?: string
}

interface IClientInfo {
  ip: string,
  userAgent: string,
  timezone: string,
}

interface IState {
  loading: number,
  pageTitle?: string,
  companyName?: string,
  companyDescription?: string,
  backgroundColour?: string,
  foregroundColour?: string,
  logoUrl?: string,
  ip?: string,
  userAgent?: string,
  timezone?: string,
}

export const startRequest = () => ({ type: START_REQUEST });
export const endRequest = () => ({ type: END_REQUEST });
export const setPageTitle = (title: string) => ({ type: SET_PAGE_TITLE, payload: title });
export const setFormProperties = (props: IFormProperties) => ({ type: SET_FORM_PROPERTIES, payload: props });
export const setClientInfo = (props: IClientInfo) => ({ type: SET_CLIENT_INFO, payload: props });

const initialState: IState = {
  loading: 0,
  pageTitle: undefined,
  companyName: undefined,
  companyDescription: undefined,
  backgroundColour: undefined,
  foregroundColour: undefined,
  logoUrl: undefined,
  ip: undefined,
  userAgent: undefined,
  timezone: undefined,
};

const stateReducer = (state: IState = initialState, action): IState => {
  switch (action.type) {
    case SET_CLIENT_INFO:
      const clientInfo = action.payload as IClientInfo;
      return Object.assign({}, state, { ip: clientInfo.ip, userAgent: clientInfo.userAgent, timezone: clientInfo.timezone });
    case SET_FORM_PROPERTIES:
      const props = action.payload as IFormProperties;
      return Object.assign({}, state, {
        companyDescription: props.companyDescription,
        companyName: props.companyName,
        backgroundColour: props.backgroundColour,
        foregroundColour: props.foregroundColour,
        logoUrl: props.logoUrl
      });
    case SET_PAGE_TITLE:
      return Object.assign({}, state, { pageTitle: action.payload });
    case START_REQUEST:
      return Object.assign({}, state, { loading: state.loading + 1 });
    case END_REQUEST:
      return Object.assign({}, state, { loading: Math.max(state.loading - 1, 0) });
    default:
      return state;
  }
};

export default stateReducer;
