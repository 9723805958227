import { Grid, Typography, FormControl } from '@material-ui/core';
import React from 'react';
import { DynamicFieldProps, DynamicFormField } from './types';
import ReactMarkdown from 'react-markdown';

// thank you - https://stackoverflow.com/a/30106551/6195757
// javascript's atob does not do well with unicode
const base64DecodeUnicode = (str: string) => {
  return decodeURIComponent(atob(str).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

const renderMarkdown = (source: string) => {
  return <div className="content"><ReactMarkdown source={source} renderers={{
    heading: (props) => {
      return <h5 className={`title is-5`}>{props.children}</h5>
    },
    text: (props) => {
      const val = props.value
        .replace(/={2,}(\S(.*?\S)?)={2,}/img, "<span style=\"background-color:yellow\">$1</span>")
        .replace(/\n/g, "<br />")

      return <span dangerouslySetInnerHTML={{__html: val}} />;
    },
    blockquote: (props) => {
      return <blockquote style={{ paddingLeft : 20, paddingTop: 10, borderLeft: "10px solid #dddddd" }}>{props.children}</blockquote>;
    },
    table: (props) => {
        return <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">{props.children}</table>
    }
}} /></div>;
};

const getStaticText = (field: DynamicFormField) => {
  var source = field.DefaultValue || '';
  switch (field.TypeDetail) {
    case "html":
      return <div dangerouslySetInnerHTML={{ __html: source }} />
    case "html64":
      return <div dangerouslySetInnerHTML={{ __html: base64DecodeUnicode(source) }} />
    case "md":
      return renderMarkdown(source);
    case "md64":
      return renderMarkdown(base64DecodeUnicode(source));
    default:
      return <Typography variant="body1">{source}</Typography>;
  }
};

const StaticText = (props: DynamicFieldProps) => {
  const { field,mandatory } = props;

  if (field.Visible === false) {
    return null;
  }

  const style = Object.assign(props.style, {
    paddingTop: 6,
    paddingBottom: 6
  });

  return (
    <Grid item md={props.width} xs={12}>
      <FormControl id={field.Name} style={style} required={mandatory}>
        {getStaticText(field)}
      </FormControl>
    </Grid>
  );
};

export default StaticText;
