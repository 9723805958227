import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonPanel: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
    borderRadius: 0,
    padding: theme.spacing(0),
  },
  buttonPanelContainer: {
    maxHeight: 'calc(100% - 10px)',
    overflow: 'auto',
    position: 'relative',
  }
}));

const ElevationScrollContainer = ({ content, toolBarItems }) => {
  const classes = useStyles();
  const [scrollTarget, setScrollTarget] = React.useState<Node | undefined>(undefined);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: scrollTarget
  });

  return (
    <div ref={node => node ? setScrollTarget(node) : setScrollTarget(undefined)} className={classes.buttonPanelContainer}>
      <Paper elevation={trigger ? 4 : 0} className={classes.buttonPanel}>{toolBarItems}</Paper>
      <div>{content}</div>
    </div>
  );
};

export default ElevationScrollContainer;