import axios, { AxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';
import { startRequest, endRequest } from 'reducers/stateReducer';
import store from 'utilities/store';

interface Dictionary<T> {
  [Key: string]: T;
}

interface requestOptions {
  method?: string,
  responseType?: string,
  body?: string,
  data?: any,
  queryParameters?: Dictionary<string>,
  mode?: string,
  headers?: Dictionary<string>,
  params?: { definitions: string },
  validateStatus?: (s: number) => boolean,
};

interface requestBuildingSettings {
  base: string,
  cullIdsInPostRequests?: boolean
}

export const requestBuilder = (settings: requestBuildingSettings) => async (url: string, options: requestOptions = {}, customHeaders: Dictionary<string> = {}): Promise<any> => {

  const defaultOptions = (): AxiosRequestConfig & { queryParameters: object, mode: string } => ({
    method: 'GET',
    responseType: 'json',
    queryParameters: {},
    mode: 'cors',
    validateStatus: s => s >= 200 && s < 300,
    headers: url.startsWith('http') ? {} : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'OData-Version': '4.0',
      'OData-MaxVersion': '4.0',
      'ZUMO-API-VERSION': '2.0.0',
      'Prefer': 'return=representation',
    }
  });

  return new Promise(async (resolve, reject) => {

    const params: AxiosRequestConfig = Object.assign({}, defaultOptions(), options);

    if (params.data instanceof FormData) {
      for (let value of params.data.values()) {
        if (value instanceof File) {
          params.headers['FileMD5Hash'] = await getChecksum(value);
        }
      }
    }

    if (settings.cullIdsInPostRequests && params.method === 'post' && typeof params.data === 'object' && false === params.data instanceof FormData) {
      //some odata apis will fail if inserting objects with an Id field
      delete params.data.Id;
    }

    if (customHeaders) {
      for (var prop in customHeaders) {
        var v = customHeaders[prop];
        if (v === undefined && params.headers[prop]) {
          delete params.headers[prop];
        } else {
          params.headers[prop] = v;
        }
      }
    }

    store.dispatch(startRequest());

    const requestUrl = url.startsWith('http') 
      ? url : 
      settings.base + url;

    axios(requestUrl, params)
      .then((result: { data: any }) => {
        store.dispatch(endRequest());
        resolve(result.data)
      })
      .catch((error: { message: string, response?: any }) => {
        store.dispatch(endRequest());
        const message = error?.response?.data?.error?.message || error.message;
        console.error(message);
      });
  });
};

const getChecksum = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e !== null && e.target !== null) {
        const data = CryptoJS.enc.Latin1.parse(e.target.result);
        const hash = CryptoJS.MD5(data).toString();
        resolve(hash);
      }
    };
    reader.readAsBinaryString(file);
  });
};