import { FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { DynamicFieldProps } from './types';

const useStyle = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(2, 0, 0, 2)
  },
  nopadding: {
    margin: 0,
    '& > p': {
      lineHeight: 'normal',
      paddingRight: 20,
      margin: theme.spacing(3, 0, 0, 0)
    }
  }
}));

const OptionGroup = (props: DynamicFieldProps) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(props.value);
  const { field,mandatory} = props;

  const optionsList = Object.entries(JSON.parse(field.TypeDetail));
  return (
    <Grid item md={props.width ?? 'auto'} xs={12}>
      <FormControl
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        error={field.Error}
        style={props.style}
        required ={mandatory}
        component="fieldset"
      >
        <Grid spacing={0} container direction="row" alignItems="flex-start" className={classes.nopadding}>
          {field.Label && (
            <Grid item xs={12} md={8}>
              <FormLabel><ReactMarkdown className={classes.nopadding} source={field.Label} /></FormLabel>
            </Grid>
          )}
          <Grid item xs={12} md={field.Label ? 4 : 12}>
            <RadioGroup row
              ref={props.reference}
              aria-label={field.Name}
              name={field.Name}
              value={value || props.value}
              onChange={e => {
                setValue(e.target.value);
                props.formValueChanged && props.formValueChanged(field.Name, e.target.value);
              }}
            >
              {optionsList.map((option, k) => {
                let [name, value] = option;
                return <FormControlLabel key={k} value={value} control={<Radio />} label={name} />
              })}
            </RadioGroup>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

export default OptionGroup;