export const DynamicFormDefaults = {
  fieldNames: {
    state: '__STATE__',
    default: '__DEFAULT__',
  },
  format: { 
    dateFns: {
      time: 'h:mm a',
      date: 'yyyy/MM/dd',
      dateTime: 'yyyy/MM/dd h:mm a',
    },
    moment: {
      time: 'h:mm A',
      date: 'yyyy/MM/DD',
      dateTime: 'yyyy/MM/DD h:mm A',
    }
  }
}