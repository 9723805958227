import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { DynamicFieldProps } from './types';


const MultilineTextField = (props: DynamicFieldProps) => {
  const { field ,mandatory} = props;
  const [value, setValue] = React.useState(props.value);
  return (
    <Grid item md={12} xs={12}>
      <TextField
        inputRef={props.reference}
        disabled={field.ReadOnly}
        error={field.Error}
        style={props.style}
        id={field.Name}
        label={field.Label}
        value={value || props.value}
        required={mandatory}
        onChange={e => {
          setValue(e.target.value);
          props.formValueChanged && props.formValueChanged(field.Name, e.target.value);
        }}
        multiline rows={4} />
    </Grid>
  );
};

export default MultilineTextField;
