import { Accordion, AccordionSummary, AccordionDetails, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  groupHeader: {
    fontSize: '125%',
    margin: theme.spacing(8, 0, 0, 3),
    width: '100%'
  },
}));

interface DynamicFormGroupProps {
  label?: string,
  expandable?: boolean,
  expanded?: boolean,
  keyId?: string | number,
}
const DynamicFormGroup: React.FC<DynamicFormGroupProps> = (props) => {
  const classes = useStyles();

  if (props.expandable === true) {
    return (
      <Accordion style={{ width: '100%' }} expanded={props.expanded === true} key={props.keyId}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          {props.label && (
            <div className={classes.groupHeader}>{props.label}</div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <Grid container spacing={0} key={props.keyId}>
        {props.label && (
          <div className={classes.groupHeader}>{props.label}</div>
        )}
        {props.children}
      </Grid>
    );
  }
};

export default DynamicFormGroup;