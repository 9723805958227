import { Grid, TextField as MuiTextField } from '@material-ui/core';
import React from 'react';
import { DynamicFieldProps } from './types';


const Barcode = (props: DynamicFieldProps) => {
  const { field ,mandatory } = props;
  const [value, setValue] = React.useState(props.value);
  return (
    <Grid item md={props.width} xs={12}>
      <MuiTextField error={field.Error}
        inputRef={props.reference}
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        style={props.style}
        id={field.Name}
        label={field.Label}
        required={mandatory}
        onChange={e => setValue(e.target.value)}
        value={value || props.value}
      />
    </Grid>
  );
};

export default Barcode;
