import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into its own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    width: '100%'
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)',
  },
}));

export interface ListTile {
  title: string,
  imageUrl: string
}

interface Props {
  tiles: ListTile[],
  tileClass: string,
  tileWidth?: number,
  tileHeight?: number,
  titleFormat?: (title: string) => string,
  handleClick?: (tile: ListTile) => void
}

const SingleLineGridList: React.FC<Props> = ({ tiles, tileClass, handleClick, titleFormat, tileWidth = 214, tileHeight = 50 }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList}>
        {tiles.map((tile, key) => (
          <GridListTile style={{ width: tileWidth, height: tileHeight + 50 }} key={key}>
            <div onClick={() => handleClick && handleClick(tile)}>
              <img src={tile.imageUrl} alt={tile.title} className={tileClass} />
              <GridListTileBar
                title={titleFormat ? titleFormat(tile.title) : tile.title}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </div>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default SingleLineGridList;
