import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { DynamicFieldProps } from './types';

interface Props {
  options?: object
}
type AutoCompleteOptions = {
  data: string[],
}
const defaultAutoCompleteOptions: AutoCompleteOptions = {
  data: []
};
const AutocompleteList = (props: DynamicFieldProps & Props) => {
  const { field, mandatory } = props;
  const [value, setValue] = React.useState(props.value);

  const opts: AutoCompleteOptions = (field.TypeDetail)
    ? JSON.parse(field.TypeDetail) as AutoCompleteOptions
    : defaultAutoCompleteOptions;

  return (
    <Grid item md={props.width} xs={12}>
      <Autocomplete
        id={field.Name}
        value={value || props.value}
        disabled={field.ReadOnly}
        freeSolo
        options={opts.data}
        getOptionLabel={(option) => option as string + ""}
        onInputChange={(e, value) => {
          setValue(value as string);
          props.formValueChanged && props.formValueChanged(field.Name, value);
        }}
        renderInput={(params) => 
          <TextField 
            inputRef={props.reference}
            label={field.Label}
            aria-readonly={field.ReadOnly} 
            required={mandatory}
            style={props.style} 
            error={field.Error} 
            {...params} 
          />
        }
      />
    </Grid>
  );
};

export default AutocompleteList;
