export type Dictionary<T> = {
  [Key: string]: T;
}

export type StateFlowState = {
  name: string;
  label?: string;
  color?: string;
}

export interface DynamicFormField {
  Name: string,
  Label: string,
  Type: DynamicFormFieldType,
  TypeDetail: string,
  DefaultValue?: string,
  MaxWidth?: number,
  MinWidth?: number,
  ControlWidthRatio?: number,
  ReadOnly?: boolean,
  Visible?: boolean,
  Mandatory?: boolean,
  Error?: boolean,
  ErrorText?: string,
  States?: Dictionary<string[]>,
  ConditionalFlow?: string,
}

export interface DynamicFormFieldGroup {
  Name?: string,
  Label?: string,
  Expandable?: boolean,
  Expanded?: boolean,
  Fields: DynamicFormField[],
  FieldGroups?: DynamicFormFieldGroup[],
  Visible?: boolean,
  ReadOnly?: boolean
}

export type StateFlowEntries = {
  entries: ConditionalFlowEntry[]
}

export interface DynamicFormRoot extends DynamicFormFieldGroup {
  NameField: string,
  Fields: DynamicFormField[],
  FieldGroups?: DynamicFormFieldGroup[],
  StateFlow?: StateFlowEntries,
  Visible?: boolean,
  ReadOnly?: boolean,
  GlobalActions?: any[],
  AfterEachSaveActions?: any[],
  AfterFirstSaveActions?: any[],
  StateFlowTemplate?: string
}

export interface DynamicFieldProps {
  field: DynamicFormField,
  value: any,
  //handleChange: (name: string, value: any) => void,
  //handleChangeAsync: (name: string, value: any) => Promise<void>,
  reference: React.RefObject<any>,
  style: any,
  mandatory: boolean,
  width?: GridWidth,
  formValueChanged?: (name: string, value: any) => void,
  conditionalFlow: ConditionalFlowEntry[],
}

export interface ConditionalFlowEntry {
  value: any,
  blockEditFromAnonymousUser?:boolean,
  blockEdit?:boolean,
  message?: string,
  then?: ConditionalFlowEntryAction,
  else?: ConditionalFlowEntryAction
}

export interface ConditionalFlowEntryAction {
  show?: string[],
  hide?: string[],
  disable?: string[],
  enable?: string[]
}

export enum DynamicFormFieldType {
  text = 1,
  numeric = 2,
  float = 3,
  date = 4, // format in details - default in DefaultValue !! This is date
  select = 5, //type in details
  checkbox = 6,
  time = 7, // format in details - default in DefaultValue !! This is date
  largeText = 9,
  calculated = 10,
  counter = 11,
  optionGroup = 12, //like radios
  assignee = 13,
  signature = 14,
  autocomplete = 15,
  barcode = 16,
  datetime = 17,
  diagram = 18,
  staticText = 30,
}

export type GridWidth = boolean | "auto" | 4 | 2 | 12 | 6 | 1 | 3 | 5 | 7 | 8 | 9 | 10 | 11 | undefined;