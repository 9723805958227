import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Button from 'components/Buttons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dictionary } from 'types'
import { AssigneeList, Checkbox, DatePicker, DateTimePicker, Diagram, MultilineTextField, NumericTextField, OptionGroup, SelectList, Signature, StaticText, TextField, TimePicker } from './'
import Autocomplete from './Autocomplete'
import Barcode from './Barcode'
import { DynamicFormDefaults } from './constants'
import DynamicFormGroup from './DynamicFormGroup'
import { getStyle, parseDateInput } from './helpers'
import { ConditionalFlowEntry, ConditionalFlowEntryAction, DynamicFieldProps, DynamicFormField, DynamicFormFieldGroup, DynamicFormFieldType, DynamicFormRoot, GridWidth, StateFlowState } from './types'
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    marginLeft: theme.spacing(0),
    '& > *': {},
  },
  footer: {
    margin: theme.spacing(2),
  },
  dynamicForm: {
    overflow: 'hidden',
    height: '100%',
    paddingTop: theme.spacing(2),
  },
}))

const formValueChangedDebounceTimeout = 150

interface Props {
  template: DynamicFormRoot
  stateTransitions: StateFlowState[]
  coordinates?: Coordinates
  options?: object
  formData?: object
  handleSubmit?: (data: Dictionary<any>) => void
}
const DynamicForm = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { template, coordinates, options, handleSubmit, formData:initialFormData, stateTransitions } = props
  const classes = useStyles()
  const [hasErrors, setHasErrors] = React.useState(0)
  const [refresh, setRefresh] = React.useState(0)
  const theme = useTheme()
  const [formData,setFormData]=React.useState<object>({});
  const [readOnly,setReadOnly]=React.useState<boolean |undefined>(false)
  let formValueChangedTimer = React.useRef<number>()
  let referenceMap: Dictionary<React.RefObject<any>> = {}
  let componentMap: Dictionary<DynamicFormField | DynamicFormFieldGroup> = {}
  let conditionalFlowFields: Dictionary<ConditionalFlowEntry[]> = {}

  React.useEffect(() => {
  if(initialFormData)
    setFormData(initialFormData);
  },[initialFormData])
  React.useEffect(() => {
    if (formData) {
      var _currentState=Object.keys(formData).find((e) => e==DynamicFormDefaults.fieldNames.state)
      if(_currentState)
      {
        setReadOnly(blockEditFromAnon(template.StateFlow?.entries,formData[_currentState]))
      }
      else
        setReadOnly(blockEditFromAnon(template.StateFlow?.entries))
    }
  }, [formData])

  /**
   * If the component has condition flow information, parse it out and add
   * the component to the list of conditional flow entries
   *
   * @param field
   */
  const createConditionalFlowEntry = (field: DynamicFormField) => {
    const json = JSON.parse(field.ConditionalFlow || '{ "entries": [] }')
    const flowEntries = json.entries as ConditionalFlowEntry[]
    if (flowEntries.length > 0) {
      conditionalFlowFields[field.Name] = flowEntries
    }
    return flowEntries
  }

  /**
   * Create a component based on the parameters passed in.
   *
   * @param field
   * @param groups
   * @param data
   * @param width
   * @param options
   * @param key
   */
  const createComponent = (field: DynamicFormField, group: DynamicFormFieldGroup, data: Dictionary<any>, width: GridWidth, options?: object, key?: string | number) => {
    const flowEntries = createConditionalFlowEntry(field)

    const componentReference = React.createRef<any>()

    const isReadOnly = (field: DynamicFormField, group: DynamicFormFieldGroup) => {
      if (group.ReadOnly) return true
      if (field.ReadOnly) return true
      return false
    }

    field.ReadOnly = isReadOnly(field, group)

    const props: DynamicFieldProps = {
      field,
      reference: componentReference,
      conditionalFlow: flowEntries,
      width: getFieldWidth(field, width),
      value: data[field.Name],
      style: getStyle(field, theme),
      mandatory: field.Mandatory === undefined ? false : field.Mandatory,
      formValueChanged: (name: string, value: any) => {
        if (!value) {
          if (componentReference.current.id==name) {
            var fieldsvalue=getCurrentFieldValues()
            setFormData({...fieldsvalue})
          }
        }
        debounceValueChange()
      },
    }

    const getComponent = () => {
      if (field.Visible === false) {
        return null
      }

      switch (field.Type) {
        case DynamicFormFieldType.diagram:
          return <Diagram {...props} key={key} />

        case DynamicFormFieldType.barcode:
          return <Barcode {...props} key={key} />

        case DynamicFormFieldType.signature:
          return <Signature {...props} key={key} />

        case DynamicFormFieldType.staticText:
          return <StaticText {...props} key={key} />

        case DynamicFormFieldType.optionGroup:
          return <OptionGroup {...props} key={key} />

        case DynamicFormFieldType.autocomplete:
          return <Autocomplete {...props} options={options} key={key} />

        case DynamicFormFieldType.select:
          return <SelectList {...props} options={options} key={key} />

        case DynamicFormFieldType.assignee:
          return <AssigneeList {...props} options={options} key={key} />

        case DynamicFormFieldType.checkbox:
          return <Checkbox {...props} key={key} />

        case DynamicFormFieldType.text:
          return <TextField {...props} key={key} />

        case DynamicFormFieldType.numeric:
        case DynamicFormFieldType.float:
          return <NumericTextField {...props} key={key} />

        case DynamicFormFieldType.largeText:
          return <MultilineTextField {...props} key={key} />

        case DynamicFormFieldType.datetime:
          return <DateTimePicker {...props} key={key} />

        case DynamicFormFieldType.date:
          return <DatePicker {...props} key={key} />

        case DynamicFormFieldType.time:
          return <TimePicker {...props} key={key} />

        default:
          console.info(`unhandled form field type: ${props.field.Type}`)
          return null
      }
    }

    const component = getComponent()

    if (component !== null) {
      referenceMap[field.Name] = componentReference
    }

    // keep a reference - faster than doing a recursive search each time
    if (field.Name) {
      componentMap[field.Name] = field
    }

    return component
  }

  /**
   * Get a reasonable grid width value based on the components in the group
   *
   * @param group
   */
  const getPreferredColumnWidth = (group: DynamicFormFieldGroup): GridWidth => {
    switch (group.Fields.length) {
      case 0:
        return 12
      case 1:
        return 12
      case 2:
        return 6
      case 3:
        return 4
      case 4:
        return 6
      default:
        return 'auto'
    }
  }

  /**
   * respect the ControlWidthRatio attribute but default to some reasonable default. The
   * grid system uses widths [0 - 12] so we'll round up to the nearest integer.
   *
   * @param field
   * @param defaultWidth
   */
  const getFieldWidth = (field: DynamicFormField, defaultWidth: GridWidth): GridWidth => {
    if (!field.ControlWidthRatio) {
      return defaultWidth
    }
    if (field.ControlWidthRatio <= 0 || field.ControlWidthRatio > 1) {
      return defaultWidth
    }
    const w = Math.ceil(12 * field.ControlWidthRatio) as GridWidth
    return w
  }

  /**
   * Will return the 'key' part of the option key / value pair based on the
   * supplied value - we return '' otherwise so that the control is not marked
   * as 'uncontrolled' which, React does not like
   *
   * @param key
   * @param id
   * @param options
   */
  const getSelectOptionIdFromDefaultValue = (key: any, id: string, options?: object) => {
    if (options && options[id]) {
      const entry = Object.entries(options[id]).find((e) => e[1] === key)
      return entry ? entry[0] : ''
    } else {
      return ''
    }
  }

  /**
   * Prepare and initialize the data array using known values, defaults and client data
   *
   * @param group
   * @param coordinates
   * @param data
   */
  const initializeDataArray = (group: DynamicFormFieldGroup, coordinates?: Coordinates, data: Dictionary<any> = {}): Dictionary<any> => {
    group.Fields.forEach((field) => {
      if (!data[field.Name] && field.Name) {
        switch (field.Type) {
          case DynamicFormFieldType.staticText:
            data[field.Name] = ''
            break
          case DynamicFormFieldType.select:
            // this works around a design pooh-pooh where the default value is set to the 'name' of the option
            // while the value is set / returns the id part of the option
            data[field.Name] = getSelectOptionIdFromDefaultValue(field.DefaultValue, field.TypeDetail, options)
            break
          case DynamicFormFieldType.date:
          case DynamicFormFieldType.datetime:
            let defaultDate = moment()
            if (field.DefaultValue && field.DefaultValue !== 'NOW') {
              const d = new Date(field.DefaultValue)
              if (d instanceof Date && !isNaN(d.getTime())) {
                defaultDate = moment(d)
              }
            }
            data[field.Name] = defaultDate.format('YYYY-MM-DDTHH:mmZZ')
            break
          default:
            switch (field.Name) {
              case 'sm_populate_latitude':
                data[field.Name] = coordinates?.latitude
                break
              case 'sm_populate_longitude':
                data[field.Name] = coordinates?.longitude
                break
              default:
                data[field.Name] = field.DefaultValue || ''
            }
            break
        }
      }
    })
    group.FieldGroups &&
      group.FieldGroups.forEach((group) => {
        data = initializeDataArray(group, coordinates, data)
      })
    return data
  }

  /**
   * Execute the actions passed in
   *
   * @param action
   */
  const handleConditionalFlowAction = (action: ConditionalFlowEntryAction) => {
    action.show?.forEach((name) => {
      if (componentMap[name]) componentMap[name].Visible = true
    })
    action.hide?.forEach((name) => {
      if (componentMap[name]) componentMap[name].Visible = false
    })
    action.enable?.forEach((name) => {
      if (componentMap[name]) {
        componentMap[name].Visible = true
        componentMap[name].ReadOnly = false
      }
    })
    action.disable?.forEach((name) => {
      if (componentMap[name]) {
        componentMap[name].Visible = true
        componentMap[name].ReadOnly = true
      }
    })
  }

  /**
   * Check the entry against the value and execute actions accordingly
   *
   * @param entries
   * @param value
   */
  const handleConditionalFlow = (entries: ConditionalFlowEntry[], value: string | number | boolean) => {
    if (value !== undefined) {
      entries.forEach((entry) => {
        if (entry.value === value) {
          entry.then && handleConditionalFlowAction(entry.then)
        } else {
          entry.else && handleConditionalFlowAction(entry.else)
        }
      })
    }
  }

  /**
   * Digs through a 'group' reference to find controls and checks for a 'checked' value to return
   *
   * @param reference The reference to dig through
   */
  const getValueFromFormGroupReference = (reference: any) => {
    const children = Array.from(reference.children) as any[]
    for (let i = 0; i < children.length; i++) {
      const node = children[i]
      if (node.control && node.control.type === 'radio') {
        if (node.control.checked) {
          return node.control.value
        }
      }
    }
    return undefined
  }

  /**
   * Based on properties of the component reference, dig out the current value
   *
   * @param reference A component reference
   */
  const getReferenceValue = (reference: any, field: DynamicFormField) => {
    if (reference.children && reference.children.length > 0) {
      return getValueFromFormGroupReference(reference)
    }
    if (!reference.type) {
      switch (reference.nodeName) {
        case 'IMG':
          return reference.src
      }
    }
    if (!field) {
      return reference.value
    }
    switch (field.Type) {
      case DynamicFormFieldType.checkbox:
        return reference.checked
      case DynamicFormFieldType.date:
      case DynamicFormFieldType.datetime:
      case DynamicFormFieldType.time:
        return parseDateInput(reference.value).toDate()
      default:
        return reference.value
    }
  }

  /**
   * collect a current snapshot of the field values
   */
  const getCurrentFieldValues = () => {
    const dict: Dictionary<any> = {}
    Object.keys(referenceMap).forEach((name) => {
      const c = referenceMap[name]
      if (c.current) {
        dict[name] = getReferenceValue(c.current, componentMap[name] as DynamicFormField)
      }
    })
    return dict
  }

  /**
   * Create all of the components in the provided group, recursively
   *
   * @param group the field group being parsed
   * @param data the initial values for the components
   * @param key a key to be applied (from .map())
   */
  const generateComponentGroup = (group: DynamicFormFieldGroup, data: Dictionary<any>, options: object, readonly: boolean, theme: Theme, key: string | number | undefined = undefined) => {
    var preferredWidth = getPreferredColumnWidth(group)

    if (group.Name) {
      componentMap[group.Name] = group
    }
    if (group.Visible === false) {
      return null
    }

    return (
      <DynamicFormGroup expanded={group.Expanded} expandable={group.Expanded} label={group.Label} key={key}>
        {group.Fields.map((field, key) => createComponent(field, group, data, preferredWidth, options, key))}
        {group.FieldGroups &&
          group.FieldGroups.map((group, i) => {
            return generateComponentGroup(group, data, options, readonly, theme, i)
          })}
      </DynamicFormGroup>
    )
  }

  /**
   * Rather than having the form re-render on every single value update we
   * will wait for value updates to pause for a small amount of time and then
   * we will trigger the update.
   */
  const debounceValueChange = () => {
    if (formValueChangedTimer.current) {
      clearTimeout(formValueChangedTimer.current)
    }
    formValueChangedTimer.current = window.setTimeout(() => {
      const fieldValues = getCurrentFieldValues()
      Object.keys(conditionalFlowFields).forEach((name) => {
        handleConditionalFlow(conditionalFlowFields[name], fieldValues[name])
      })
      // i don't want every error showing whenever a value changes only update
      // as we remove errors
      if (hasErrors) {
        const errorFields = checkFormFields(template)
        setHasErrors(errorFields.length)
      }
      setRefresh(refresh + 1)
    }, formValueChangedDebounceTimeout)
  }

  /**
   * Look for errors in the form, set field values appropriately and return error count
   *
   * @param group
   */
  const checkFormFields = (group: DynamicFormFieldGroup, errorFields: DynamicFormField[] = []): DynamicFormField[] => {
    const fieldValues = getCurrentFieldValues()
    group.Fields.forEach((field) => {
      field.Error = false
      field.ErrorText = ''
      if (visibleFieldIsMissingMandatoryValue(field, fieldValues[field.Name])) {
        field.Error = true
        field.ErrorText = 'Please provide a value for this field'
        errorFields.push(field)
      }
    })
    group.FieldGroups?.forEach((group) => checkFormFields(group, errorFields))
    return errorFields
  }

  const visibleFieldIsMissingMandatoryValue = (field: DynamicFormField, value: any) => {
    return !value && field.Mandatory === true && fieldIsVisible(field) === true && field.ReadOnly === false
  }

  const fieldIsVisible = (field: DynamicFormField) => {
    if (field.Visible !== true) {
      return false
    }
    let visible = true
    findLinkingNodes(field, template, (group) => {
      if (group.Visible === false) visible = false
    })
    return visible
  }

  const findLinkingNodes = (field: DynamicFormField, template: DynamicFormFieldGroup, callback: (group: DynamicFormFieldGroup) => void) => {
    for (let j = 0; j < template.Fields.length; j++) {
      if (template.Fields[j].Name === field.Name) return true
    }
    if (template.FieldGroups) {
      for (let i = 0; i < template.FieldGroups.length; i++) {
        let found = findLinkingNodes(field, template.FieldGroups[i], callback)
        if (found !== false) {
          callback?.call(null, template.FieldGroups[i])
          return found
        }
      }
    }
    return false
  }

  const findScrollingPanel = () => {
    if(ref){
      let el: HTMLElement | null = (ref as any).current as HTMLElement
      while (el !== null) {
        if (el?.className.indexOf('scrolling-panel') !== -1) {
          return el
        }
        el = el.parentElement
      }
    }
    return null
  }

  const scrollToElement = (element: HTMLElement) => {
    let formControlRoot: HTMLElement | null = element
    while (formControlRoot && formControlRoot.className.indexOf('MuiFormControl-root') === -1) {
      formControlRoot = formControlRoot.parentElement
    }
    if (formControlRoot) {
      element = formControlRoot
    }
    const panel = findScrollingPanel()
    if (panel) {
      const distance = Math.abs(panel.getBoundingClientRect().y - element.getBoundingClientRect().y)
      panel.parentElement?.scrollTo({ top: distance })
    }
  }

  const submitFormIfThereAreNoErrors = (transitionToState?: string) => {
    const errorFields = checkFormFields(template)
    setHasErrors(errorFields.length)
    if (errorFields.length > 0) {
      scrollToElement(referenceMap[errorFields[0].Name].current)
    }
    if (errorFields.length === 0 && handleSubmit) {
      const fieldValues = getCurrentFieldValues()
      if (transitionToState) {
        handleSubmit({ ...fieldValues, [DynamicFormDefaults.fieldNames.state]: transitionToState })
      } else {
        handleSubmit(fieldValues)
      }
    }
  }
  const getSubmitButtons = () => {
    if (stateTransitions.length === 0) {
      return <Button.SubmitButton click={() => submitFormIfThereAreNoErrors()} />
    }
    return (
      <>
        {stateTransitions.map((transition, i) => {
          return <Button.SubmitButton key={i} style={{ color: transition.color }} label={transition.label} click={() => submitFormIfThereAreNoErrors(transition.name)} />
        })}
      </>
    )
  }

  const initForm = () => {
    const data = initializeDataArray(template, coordinates, formData)
    const form = generateComponentGroup(template, data, options || {}, false, theme, undefined)
    // trigger 1, and only 1, refresh after to set the component values
    if (refresh === 0) {
      setRefresh(refresh + 1)
    }
    // once the values have been applied, allow state / condition flow to run
    if (refresh === 1) {
      if (template.StateFlow) {
        handleConditionalFlow(template.StateFlow.entries, (initialFormData?initialFormData[DynamicFormDefaults.fieldNames.state]:DynamicFormDefaults.fieldNames.default)||DynamicFormDefaults.fieldNames.default)
      }
      Object.keys(conditionalFlowFields).forEach((name) => {
        handleConditionalFlow(conditionalFlowFields[name], data[name])
      })
      if(blockEdit())
      {
        Object.keys(componentMap).forEach((name) => {
          componentMap[name].ReadOnly=true})
      }
    }
    return form
  }
  const blockEditFromAnon = (StateFlow:ConditionalFlowEntry[]|undefined,currentState?:string) => {
    if(!currentState)
       return(StateFlow?.find((entry) => entry.value==DynamicFormDefaults.fieldNames.default)?.blockEditFromAnonymousUser)
     else
       return(StateFlow?.find((entry)=> entry.value==currentState)?.blockEditFromAnonymousUser) 
  }
  const blockEdit = () => {
    if(initialFormData) {
      var key = Object.keys(initialFormData).find((e)=> e==DynamicFormDefaults.fieldNames.state)
      if(!key)
        return((template.StateFlow?.entries.find((entry) => entry.value==DynamicFormDefaults.fieldNames.default)?.blockEdit)??false)
      else {
        var _currentState=initialFormData[key]
        return((template.StateFlow?.entries.find((entry) => entry.value==_currentState)?.blockEdit)??false)
      }
      }
  }


  return (
    <div className={classes.dynamicForm} ref={ref}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={`dynamic-form-root ${classes.root}`}>
          {initForm()}
          {hasErrors > 0 && (
            <Alert severity="error">
              <FormattedMessage id="form-errors" defaultMessage="It looks like there are some errors in the form. Please make sure to fill in any mandatory fields (marked in red)." />
            </Alert>
          )}
          <div className={classes.footer}>{!readOnly && getSubmitButtons()}</div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
})

export default DynamicForm
