import { apiSettings } from 'etc/settings';
import { requestBuilder } from 'utilities/request';
import store from 'utilities/store';
import { FormDataSubmissionRequest, AnonymousFormEntryDto } from 'types';
import { setFormProperties } from 'reducers/stateReducer';

const request = requestBuilder(apiSettings.workaware);

export const validGuid = (guid: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
}

export const getCompletedForm = (id: string) => new Promise<AnonymousFormEntryDto>((resolve, reject) => {
  request(`/api/v2/anon/entry/${id}`)
    .then((dto: AnonymousFormEntryDto) => {
      store.dispatch(setFormProperties({
        companyName: dto.CompanyName,
        companyDescription: dto.CompanyFormName,
        backgroundColour: dto.BackgroundColour,
        foregroundColour: dto.ForegroundColour,
        logoUrl: dto.LogoUrl,
      }));
      resolve(dto);
    })
    .catch(error => reject(error));
});

export const getFormTemplate = (id: string) => new Promise<AnonymousFormEntryDto>((resolve, reject) => {
  request(`/api/v2/anon/${id}`)
    .then((dto: AnonymousFormEntryDto) => {
      store.dispatch(setFormProperties({
        companyName: dto.CompanyName,
        companyDescription: dto.CompanyFormName,
        backgroundColour: dto.BackgroundColour,
        foregroundColour: dto.ForegroundColour,
        logoUrl: dto.LogoUrl,
      }));
      resolve(dto);
    })
    .catch(error => reject(error));
});

export const getFormCompletion = (id: string, formId: string) => new Promise<AnonymousFormEntryDto>((resolve, reject) => {
  request(`/api/v2/anon/${id}/${formId}`)
    .then((dto: AnonymousFormEntryDto) => {
      store.dispatch(setFormProperties({
        companyName: dto.CompanyName,
        companyDescription: dto.CompanyFormName,
        backgroundColour: dto.BackgroundColour,
        foregroundColour: dto.ForegroundColour,
        logoUrl: dto.LogoUrl,
      }));
      resolve(dto);
    })
    .catch(error => reject(error));
});

export const submitFormData = (data: FormDataSubmissionRequest) => new Promise<void>((resolve, reject) => {
  request(`/api/v2/anon`, { method: 'post', data })
    .then(() => resolve())
    .catch(error => reject(error));
});
export const updateAnonymousFormData = (data: FormDataSubmissionRequest) => new Promise<void>((resolve, reject) => {
  request(`/api/v2/anon/entry`, { method: 'put', data })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const sendVerificationEmail = (entryId: string) => new Promise<void>((resolve, reject) => {
  request(`/api/v2/anon/verification/email/${entryId}`)
    .then(() => resolve())
    .catch(error => reject(error));
});

export const clientInfo = () => new Promise<any>((resolve, reject) => {
  request('https://www.cloudflare.com/cdn-cgi/trace', { responseType: 'text/html' })
    .then((data: string) => {
      const info = {};
      data.split("\n").forEach(line => {
        if (line) {
          const parts = line.split('=');
          info[parts[0]] = parts[1];
        }
      })
      resolve(info);
    });
});

export const getLocation = (success: (coords: Coordinates) => void) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      success(position.coords);
    });
  }
};