import { Container, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { DynamicForm } from 'components/dynamicForms';
import { DynamicFormDefaults } from 'components/dynamicForms/constants';
import { ConditionalFlowEntry, DynamicFormField, DynamicFormFieldGroup, DynamicFormRoot, StateFlowState } from 'components/dynamicForms/types';
import ElevationScrollContainer from 'components/ElevationScrollContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import CompanyFormMessageDialog from 'containers/dialogs/CompanyFormMessageDialog';
import ConfirmationDialog from 'containers/dialogs/ConfirmationDialog';
import { uiSettings } from 'etc/settings';
import moment from 'moment';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { AnonymousFormEntryDto, Dictionary, IFieldsForInclusion, IMessageOptions, IStateOptions } from 'types';
import { getFormCompletion, getFormTemplate, getLocation, sendVerificationEmail, submitFormData, validGuid ,updateAnonymousFormData} from 'utilities/helpers';
import store from 'utilities/store';

const useStyle = makeStyles((theme) => ({
  container: {
    height: '100%',
    background: uiSettings.background
  },
  qrcode: {
    width: 360,
    height: 360,
  },
  infoMessage: {
    margin: theme.spacing(3)
  },
  header: {
    padding: theme.spacing(1),
    '& div': {
      margin: theme.spacing(0, 0, 0, 0.5),
      fontSize: '8pt'
    },
    '& h2': {
      margin: theme.spacing(2, 0, 0, 0.5),
      fontSize: '20pt'
    },
    '& .MuiAvatar-root': {
      margin: theme.spacing(2, 0, 0, 0),
      fontSize: '20pt'
    }
  }
}));

enum ProcessState {
  loading,
  filling,
  submitting,
  showResponseCode,
  showThankYou
};
enum AnonymousFormStatus {
  AnonymousFormEntry,
  AnonymousFormEdit

}

const AnonymousFormPage :React.FC = () => {
  const { id, formid } = useParams();
  const classes = useStyle();
  const [coordinates, setCoordinates] = React.useState<Coordinates | undefined>(undefined);
  const [stateTransitions, setStateTransitions] = React.useState<StateFlowState[]>([]);
  const [options, setOptions] = React.useState<object>({});
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);
  const [processState, setProcessState] = React.useState<ProcessState>(ProcessState.loading);
  const [template, setTemplate] = React.useState<DynamicFormRoot>({ NameField: '', Fields: [], Visible: true, ReadOnly: false });
  const [form, setForm] = React.useState<AnonymousFormEntryDto>({} as AnonymousFormEntryDto);
  const [updatedData,setUpdatedData] = React.useState<Dictionary<any>>({});
  const [data, setData] = React.useState<object>({});
  const [openFormMessage, setOpenFormMessage] = React.useState<boolean>(true)
  const [formMessage,setFormMessage] = React.useState<string|undefined>()
  const [anonymousFormStatus,setAnonymousFormStatus]= React.useState<AnonymousFormStatus>(AnonymousFormStatus.AnonymousFormEntry);
 React.useEffect(() => {
    getLocation(result => setCoordinates(result));
    const apiCall = () => {
      if (validGuid(id)) {
        if (validGuid(formid)) {
          setAnonymousFormStatus(AnonymousFormStatus.AnonymousFormEdit)
          return getFormCompletion(id, formid);
        }
        return getFormTemplate(id);
      }
    }
    apiCall()?.then(result => {
      setForm(result);

      setStateTransitions(result.StateTransitions || []);
      setOptions((typeof result.FormOptions === 'string') ? JSON.parse(result.FormOptions) : result.FormOptions);
       var _template=JSON.parse(result.Form)
      setTemplate(_template);
      setData(JSON.parse(result.FormData ?? '{}'));
      setProcessState(ProcessState.filling);
      setOpenFormMessage(true)
    })
;
    // eslint-disable-next-line
  }, [id, formid]);

React.useEffect(() => {
     if(data&&anonymousFormStatus==AnonymousFormStatus.AnonymousFormEdit)
     {
       var key=Object.keys(data).find((e) => e==DynamicFormDefaults.fieldNames.state)
       if(key)
       {
         setFormMessage(getFormMessage(template.StateFlow?.entries,data[key]))
       }
     }
     else
      setFormMessage(getFormMessage(template.StateFlow?.entries))
	   },[data])

  const submitForm = () => {
    setConfirmSubmit(false);
    setProcessState(ProcessState.submitting);
    if (form) {
      const currentState = store.getState().state;
      if(anonymousFormStatus==AnonymousFormStatus.AnonymousFormEdit)
      {
        updateAnonymousFormData({
        formId: form.Id,
        entryId: form.EntryId,
        formData: JSON.stringify(updatedData),
        clientIp: currentState.ip,
        clientUserAgent: currentState.userAgent,
        clientTimezone: currentState.timezone
      }).then(() => {
         form.ShowResponseCode
          ? setProcessState(ProcessState.showResponseCode)
          : setProcessState(ProcessState.showThankYou);
         if (form.SendVerificationEmails) {
          sendVerificationEmail(form.EntryId);
        }
      });
      }
      else
      {
        submitFormData({
        formId: form.Id,
        entryId: form.EntryId,
        formData: JSON.stringify(updatedData),
        clientIp: currentState.ip,
        clientUserAgent: currentState.userAgent,
        clientTimezone: currentState.timezone
        }).then(() => {
        form.ShowResponseCode
          ? setProcessState(ProcessState.showResponseCode)
          : setProcessState(ProcessState.showThankYou);
        if (form.SendVerificationEmails) {
          sendVerificationEmail(form.EntryId);
        }
        });
      }
    }
  };

  const getAnswerStates = (group: DynamicFormFieldGroup, answers: Dictionary<any>, states: Dictionary<string> = {}) => {
    group.Fields.forEach(field => {
      if (field.States && answers[field.Name]) {
        const stateKeys = Object.keys(field.States);
        if (stateKeys.length > 0) {
          const answer = answers[field.Name];
          stateKeys.forEach(state => {
            if (field.States && field.States[state].includes(answer)) {
              states[field.Name] = state;
            }
          });
        }
      }
    });
    group.FieldGroups?.forEach(g => {
      getAnswerStates(g, answers, states);
    });
    return states;
  };

  const stateMatches = (name: string, selector: string, data: Dictionary<string>) => {
    const values = {};
    Object.values(data).forEach(state => values[state] ? values[state]++ : values[state] = 1);
    switch (selector) {
      case 'any': // looking for 1+ states in the data
        return (values[name] ? true : false);
      case 'all': // looking for only one state
        return (values[name] && Object.keys(values).length === 1);
    }
  };

  const getStateOptions = (): IStateOptions | undefined => {
    const answerStates = getAnswerStates(JSON.parse(form.Form), data);
    const stateOptions = JSON.parse(form.StateOptions || '{}');
    const stateKeys = Object.keys(stateOptions);
    for (let i = 0; i < stateKeys.length; i++) {
      const key = stateKeys[i];
      const [stateName, stateSelector] = key.split(':');
      if (stateMatches(stateName, stateSelector, answerStates)) {
        return stateOptions[key];
      }
    }
    return undefined;
  };

  const getQRUrl = () => {
    const opts = getStateOptions();

    const bg = opts?.qrBgColour || 'fff';
    const fg = opts?.qrFgColour || '000';

    // const encodedUrl = btoa(`https://forms.workaware.com/entry/${form.EntryId}`);
    const encodedUrl = btoa(`https://forms.workaware.com/${form.Id}/${form.EntryId}`);
    return `https://api.workaware.com/api/v2/anon/qr/${encodedUrl}?ms=6&bg=${bg}&fg=${fg}`;
  };

  const getFormField = (template: DynamicFormFieldGroup, name: string): DynamicFormField | undefined => {
    for (let i = 0; i < template.Fields.length; i++) {
      if (template.Fields[i].Name === name) {
        return template.Fields[i];
      }
    }
    if (template.FieldGroups) {
      for (let i = 0; i < template.FieldGroups.length; i++) {
        const result = getFormField(template.FieldGroups[i], name);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  const getMessage = (message: IMessageOptions, template: DynamicFormFieldGroup, answers: Dictionary<any>): JSX.Element => {
    switch (message.type) {
      case "html":
        return <div dangerouslySetInnerHTML={{ __html: message.content as string }} />;
      case "html64":
        return <div dangerouslySetInnerHTML={{ __html: atob(message.content as string) }} />;
      case "md":
        return <ReactMarkdown source={message.content as string} />;
      case "md64":
        return <ReactMarkdown source={atob(message.content as string)} />;
      case "field-summary":
        const fields = (message.content as IFieldsForInclusion).fields.map((field, i) => {
          const f = getFormField(template, field);
          switch (f?.Type) {
            case 5:
              const options = form.FormOptions[f.TypeDetail];
              return (<p key={i}>{field}: {options[answers[field]] || '-- unkown --'}</p>);
            default:
              return (<p key={i}>{field}: {answers[field] || '-- unkown --'}</p>);
          }
        })
        return <>{fields}</>;
      default:
        return <React.Fragment />;
    }
  };

  const getThankYouMessage = (form: AnonymousFormEntryDto, answers: Dictionary<any>) => {
    const opts = getStateOptions();
    const m = moment();
    const template: DynamicFormRoot = JSON.parse(form.Form);

    return (
      <>
        <h3>Thank you for completing the form</h3>
        {form.SendVerificationEmails && (
          <p>Please check your email for a verification code.</p>
        )}
        <b>{m.toLocaleString()}</b>
        {opts?.message && (
          getOptionalMessages(opts?.message, template, answers)
        )}
      </>
    );
  };

  const getOptionalMessages = (messages: IMessageOptions[], template: DynamicFormFieldGroup, answers: Dictionary<any>) => (
    <>
      {
        messages.map((message, i) => (
          <div key={i}>
            {getMessage(message, template, answers)}
          </div>
        ))
      }
    </>
  );


  const render = () => {
    switch (processState) {
      case ProcessState.loading:
        return (
          <>
            <LinearProgress />
            <Typography variant="body1" className={classes.infoMessage}>loading...</Typography>
          </>
        );
      case ProcessState.submitting:
        return (
          <>
            <LinearProgress />
            <Typography variant="body1" className={classes.infoMessage}>submitting...</Typography>
          </>
        );
      case ProcessState.showResponseCode:
        return (
          <Grid container direction="row">
            <Grid item style={{ padding: 10 }} md={4} xs={12}>
              <img className={classes.qrcode} src={getQRUrl()} alt="qrcode" />
              <hr />
              <p>You can access the form using the QR code.</p>
            </Grid>
            <Grid item style={{ padding: 10 }} md={8} xs={12}>
              {getThankYouMessage(form, data)}
            </Grid>
          </Grid>
        );
      case ProcessState.showThankYou:
        return (
          <Grid container>
            <Grid item style={{ padding: 10 }}>
              {getThankYouMessage(form, data)}
            </Grid>
          </Grid>
        );
      case ProcessState.filling:
        return (
          <Container maxWidth="md" style={{ height: '100%', overflow: 'hidden', padding: 0 }}>
            <ErrorBoundary>
              <ElevationScrollContainer
                toolBarItems={
                  <Grid container className={classes.header}>
                    <Grid item>
                      <h2>{form.Name}</h2>
                      <div>{form.CompanyFormDescription}</div>
                      <div>form created {moment(form.Created).fromNow()}</div>
                    </Grid>
                  </Grid>
                }
                content={
                  <DynamicForm
                    stateTransitions={stateTransitions}
                    coordinates={coordinates}
                    options={options}
                    template={template}
                    formData={data}
                    handleSubmit={(data) => {
                      setUpdatedData(data)
                      setConfirmSubmit(true)
                    }}
                  />
                }
              />
            </ErrorBoundary>
          </Container>
        );
    }
  };

  const getFormMessage = (StateFlow:ConditionalFlowEntry[]|undefined,currentState?:string) => {
     if(!currentState)
       return(StateFlow?.find((entry) => entry.value==DynamicFormDefaults.fieldNames.default)?.message)
     else
       return(StateFlow?.find((entry)=> entry.value==currentState)?.message) 
  }
   const handleClose =() =>{
     setOpenFormMessage(false);
	   }

  return (
    <div className={classes.container}>
      {render()}

      <ConfirmationDialog
        open={confirmSubmit}
        message="Are you sure you want to submit this form?"
        handleCancel={() => setConfirmSubmit(false)}
        handleOk={() => submitForm()}
      />
       {formMessage && <CompanyFormMessageDialog open={openFormMessage} message={formMessage} handleClose={handleClose}>
	           </CompanyFormMessageDialog>}
    </div>
  );
}

export default AnonymousFormPage;