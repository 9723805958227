import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'utilities/store';
import { IntlProvider } from 'react-intl';
import { getLocale } from 'etc/i18nConfig';

const locale = getLocale('en');

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <IntlProvider key={locale.code} locale={locale.code} messages={locale.messages}>
          <App />
        </IntlProvider>
      </Router>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
