import { darken } from "@material-ui/core/styles";

export const apiSettings = {
  workaware: {
    base: 'https://api-staging.workaware.com',
    //base: 'http://localhost:5044',
    cullIdsInPostRequests: true,
  },
  documents: {
    base: 'https://documents.workaware.com/api/v1'
  }
};

export const uiSettings = {
  drawerWidth: 240,
  background: 'white',
  header: {
    appBarHeight: 100,
    background: darken('#7FFF00', 0.25)
  }
};