import React from 'react';

const RouteNotFound = () => {
  return (
    <article>
      <h3>404: No Matching Route or Resource Found</h3>
      <pre>The request could not be matched to any existing route pattern</pre>
    </article>
  );
};

export default RouteNotFound;