import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/images/logo-white.png';
import info from './../../package.json';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    marginLeft: 24,
    '& div': {
      position: 'relative',
      display: 'inline-block'
    },
    color: 'white',
  },
  version: {
    position: 'absolute',
    fontSize: 10,
    right: 0,
    bottom: -10
  },
  logo: {
    height: 30,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  spin: {
    transform: 'rotate(360deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
const Branding = ({ title=null, expanded=false }) => {

  const classes = useStyles();
  return (
    <React.Fragment>
      <img src={logo} className={clsx(classes.logo, expanded && classes.spin)} alt="Company Logo" />
      <div className={classes.title}>
        <div>
          <Typography variant="h6" noWrap >
            Workaware.Forms
          </Typography>
          <Typography variant="h6" noWrap className={classes.version}>
            ver {info.version}
          </Typography>
        </div>
        <div>
          <Typography style={{ marginLeft: 8 }} variant="h6" noWrap>{title ? ' / ' : ''}{title}</Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Branding;