import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Buttons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  open: boolean,
  message: string | JSX.Element,
  title?: string | JSX.Element,
  handleOk?: () => void,
  handleCancel?: () => void
}

const ConfirmationDialog = ({open, message, title=<FormattedMessage id="confirmation" defaultMessage="Please Confirm" />, handleOk, handleCancel}:Props) => {
  return (
    <Dialog open={open === true} onClose={() => handleCancel && handleCancel()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button.OkButton click={() => handleOk && handleOk()} />
        <Button.CancelButton click={() => handleCancel && handleCancel()} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
