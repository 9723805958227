import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { DynamicFieldProps } from './types';

interface Props {
  options?: object
}
const AssigneeList = (props: DynamicFieldProps & Props) => {
  const { field, options, mandatory } = props;
  const [value, setValue] = React.useState(props.value);
  const optionsId = '_assignments_';
  return (
    <Grid item md={props.width} xs={12}>
      <FormControl
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        style={props.style}
        error={field.Error}
        required={mandatory}
      >
        <InputLabel id={field.Name}>{field.Label}</InputLabel>
        <Select
          inputRef={props.reference}
          labelId={field.Name}
          id={field.Name}
          value={value || props.value}
          onChange={e => setValue(e.target.value as string)}
        >
          {options && options[optionsId] && Object.entries(options[optionsId]).map(e => (
            <MenuItem key={e[0]} value={e[0]}>{e[1] as string}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default AssigneeList;
