import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, makeStyles, Paper } from '@material-ui/core';
import Button from 'components/Buttons';
import moment from 'moment';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import SingleLineGridList, { ListTile } from './SingleLineGridList';
import { DynamicFieldProps } from './types';

const useStyle = makeStyles((theme) => ({
  signature: {
    height: 40,
    width: 214,
  },
  emptyBox: {
    height: 28.19,
    borderColor: "#999999",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid"
  },
  emptyBoxDisabled: {
    height: 28.19,
    borderColor: "#dddddd",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid"
  },
  filledBox: {
    height: 28.19,
  }
}));


interface SavedSignatures {
  signatures: ListTile[]
}

const savedSignaturesKey = 'workaware-saved-signature';

const getSignaturesFromStorage = (): SavedSignatures => {
  const storedObj = JSON.parse(localStorage.getItem(savedSignaturesKey) || '{ "signatures": [] }') as SavedSignatures;
  return { ...storedObj };
};

const putSignaturesIntoStorage = (signatures: ListTile[]) => {
  const data = JSON.stringify({ signatures });
  localStorage.setItem(savedSignaturesKey, data);
};

const Signature = (props: DynamicFieldProps) => {
  const { field,mandatory } = props;
  const classes = useStyle();
  const [sigCanvas, setSigCanvas] = React.useState(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(props.value);
  const [signature, setSignature] = React.useState<ListTile>({ title: '', imageUrl: '' });
  const [signatures, setSignatures] = React.useState<ListTile[]>([]);

  React.useEffect(() => {
    if (open === true) {
      // load up saved signatures
      const sigs = getSignaturesFromStorage();
      setSignatures(sigs.signatures);
      // apply any signature we are working on
      if (sigCanvas && props.value) {
        (sigCanvas as any).fromDataURL(props.value);
      }
    }
    // eslint-disable-next-line
  }, [open, sigCanvas]);

  const handleOk = () => {
    const dataUrl = (sigCanvas as any).toDataURL('image/png');
    setValue(dataUrl);
    saveSignature(dataUrl);
    setOpen(false);
  };

  const handleOpen = () => {
    if (!field.ReadOnly) {
      setSignatures([{
        title: 'something',
        imageUrl: ''
      }]);
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const saveSignature = (dataUrl: string) => {
    if (signature.title === '') {
      signature.title = `${+new Date()}`;
    }
    signature.imageUrl = dataUrl;
    const filteredSignatures = signatures.filter((s) => s.title !== signature.title);
    signature.title = `${+new Date()}`;
    putSignaturesIntoStorage([...filteredSignatures, signature].sort((a: ListTile, b: ListTile) => parseInt(b.title) - parseInt(a.title)));
  };

  const handleTileClick = (tile: ListTile) => {
    if (sigCanvas) {
      setSignature(tile);
      (sigCanvas as any).clear();
      (sigCanvas as any).fromDataURL(tile.imageUrl);
    }
  };

  const getControlContainerClass = () => {
    return value
      ? classes.filledBox
      : field.ReadOnly
        ? classes.emptyBoxDisabled
        : classes.emptyBox
  };

  const getValue = (): string => value || props.value;

  return (
    <Grid item md={props.width} xs={12}>

      <FormControl
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        required ={mandatory}
        error={field.Error}
        style={Object.assign({}, { maxWidth: 240, minWidth: 240 }, props.style)}>
        <div onClick={(e) => handleOpen()}>
          <div style={{
            marginTop: 16
          }}>
            <InputLabel shrink={!!getValue()}>{props.field.Label || 'Please Sign'}</InputLabel>
            <div className={getControlContainerClass()} onClick={() => handleOpen()} >
              {getValue() && <img ref={props.reference} className={classes.signature} src={getValue()} alt="signature" />}
            </div>
          </div>
        </div>
      </FormControl>
      <Dialog maxWidth="md" open={open === true} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {props.field.Label || 'Please Sign'}
        </DialogTitle>
        <DialogContent >
          <Paper elevation={3}>
            <SignatureCanvas ref={(ref: any) => setSigCanvas(ref)} penColor='blue' canvasProps={{ width: 800, height: 150, className: 'sigCanvas' }} />
          </Paper>
          {signatures.length > 0 && (
            <>
              <br />
              <SingleLineGridList titleFormat={(title) => moment(parseInt(title)).fromNow()} tileClass={classes.signature} tiles={signatures} handleClick={(tile) => handleTileClick(tile)} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button.OkButton click={() => handleOk()} />
          <Button.CancelButton click={() => handleCancel()} />
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default Signature;
