import { Grid } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import React from 'react';
import { DynamicFieldProps } from './types';
import { DynamicFormDefaults } from './constants';
import { parseDateInput } from './helpers';

const checkTimeFormat = (format: string, defaultFormat: string) => {
  const incompatible: string[] = [ 'g', 'd', 't', 'G', 'D', 'T' ];
  if (!format || incompatible.includes(format.trim()))
  {
    return defaultFormat;
  }
  return format;
};

const TimePicker = (props: DynamicFieldProps) => {
  const { field,mandatory } = props;
  const [value, setValue] = React.useState<Date>(new Date());
  
  React.useEffect(() => {
    setValue(parseDateInput(props.value).toDate());
  }, [props.value]);

  if (field.Visible === false) {
    return null;
  }

  return (
    <Grid item md={props.width} xs={12}>
      <KeyboardTimePicker
        inputRef={props.reference}
        aria-readonly={field.ReadOnly}
        disabled={field.ReadOnly}
        error={field.Error}
        style={props.style}
        margin="normal"
        id={field.Name}
        label={field.Label}
        format={checkTimeFormat(field.TypeDetail, DynamicFormDefaults.format.dateFns.time)}
        value={value ?? ''}
        required={mandatory}
        onChange={time => {
          time && setValue(time);
          props.formValueChanged && props.formValueChanged(field.Name, time);
        }}
        KeyboardButtonProps={{ 'aria-label': 'change time' }}
      />
    </Grid>
  );
};

export default TimePicker;
