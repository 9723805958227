import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import stateReducer from 'reducers/stateReducer';

const appReducer = combineReducers({
  state: stateReducer
});

const store = createStore(
  appReducer,
  compose(applyMiddleware(...[
    // middleware
    createLogger()
  ]), ...[
    // enhancers
  ])
);

export type AppState = ReturnType<typeof appReducer>;

export default store;
