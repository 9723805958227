import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/Buttons";
import React from "react";
import { FormattedMessage } from "react-intl";

interface props
{
    open :boolean
    message? : string
    handleClose?: () => void
}

const CompanyFormMessageDialog : React.FC<props> = ({open,message,handleClose}) =>
{
    
    return ( 
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
          
      <DialogTitle id="form-dialog-title">
      <FormattedMessage id="FormMessage.title" defaultMessage="Form Message"/> <br></br>
      </DialogTitle>
      <DialogContent style={{fontSize:'16px'}}>
        <span  dangerouslySetInnerHTML={{__html: message || ''}} />
      </DialogContent>
      <DialogActions>
        <Button.OkButton click={(e) => {handleClose && handleClose()}} />
      </DialogActions>
    </Dialog>);

};
export default CompanyFormMessageDialog;
